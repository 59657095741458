import React from "react";
import { Footer } from "./components/Footer";
import Header from "./components/Header";

export const Terms = () => {
  return (
    <>
      <div className="whitebg">
        <Header />
      </div>
      <section id="privacy-policy">
        <div className="privacy-policy">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title centerheading">
                  <div className="centerit">
                    <span className="colorborder">
                      <em></em>
                    </span>
                    <h2
                      data-aos="fade-down"
                      data-aos-offset="100"
                      data-aos-easing="ease-in-sine"
                      data-aos-once="true"
                      data-aos-duration="500"
                    >
                      Terms & Conditions
                    </h2>
                  </div>
                </div>
                <div className="content text-left">
                  <p>
                    VISITORS TO THIS WEB SITE ARE BOUND BY THE FOLLOWING TERMS
                    AND CONDITIONS ("TERMS"). SO, PLEASE READ THE TERMS
                    CAREFULLY BEFORE CONTINUING TO USE THIS SITE. IF YOU DO NOT
                    AGREE WITH ANY OF THESE TERMS, PLEASE DO NOT USE THIS SITE.
                  </p>
                  <p>
                    The use of this website is subject to the following terms of
                    use:
                  </p>
                  <ul>
                    <li>
                      The content of the pages of this website is for your
                      general information and use only. It is subject to change
                      without notice.
                    </li>
                    <li>
                      This website uses cookies to monitor browsing preferences.
                      If you do allow cookies to be used, personal information
                      may be stored by us for use by third parties.
                    </li>
                    <li>
                      Neither PREMIER ENERGIES nor any third parties provide any
                      warranty or guarantee as to the accuracy, timeliness,
                      performance, completeness or suitability of the
                      information and materials found or offered on this website
                      for any particular purpose. You acknowledge that such
                      information and materials may contain inaccuracies or
                      errors and we expressly exclude liability for any such
                      inaccuracies or errors to the fullest extent permitted by
                      law.
                    </li>
                    <li>
                      Your use of any information or materials on this website
                      is entirely at your own risk, for which PREMIER ENERGIES
                      shall not be liable. It shall be your own responsibility
                      to ensure that any products, services or information
                      available through this website meet your specific
                      requirements.
                    </li>
                    <li>
                      This website contains material which is owned by or
                      licensed to us. This material includes, but is not limited
                      to, the design, layout, look, appearance and graphics.
                      Reproduction is prohibited other than in accordance with
                      the copyright notice, which forms part of these terms and
                      conditions.
                    </li>
                    <li>
                      The premierenergiesTM and all products and logos denoted
                      with “TM” or “®”are trademarks or registered trademarks of
                      PREMIER ENERGIES or its affiliates. PREMIER ENERGIES
                      trademarks may not be used in connection with any product
                      or service that is not a PREMIER ENERGIES product,
                      functions or service.
                    </li>
                    <li>
                      Unauthorized use of this website may give rise to a claim
                      for damages and/or be a criminal offence.
                    </li>
                    <li>
                      From time to time this website may also include links to
                      other websites. These links are provided for your
                      convenience to provide further information. They do not
                      signify that PREMIER ENERGIES endorse the website(s).
                      PREMIER ENERGIES has no responsibility for the content of
                      the linked website(s).
                    </li>
                    <li>
                      Applicable Law and Jurisdiction of this WEBSITE are
                      governed by and to be interpreted in accordance with laws
                      of India, without regard to the choice or conflicts of law
                      provisions of any jurisdiction. The user/site visitor
                      agrees that in the event of any dispute arising in
                      relation to this Disclaimer or any dispute arising in
                      relation to the web site whether in contract or tort or
                      otherwise, to submit to the jurisdiction of the courts
                      located at Hyderabad only for the resolution of all such
                      disputes.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};